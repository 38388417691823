"use client";
import React from "react";
import { motion } from "framer-motion";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import { LayoutGrid } from "../components/ui/layout-grid.jsx";
import h1 from "../images/home2.jpg";
import h2 from "../images/home1.jpg";
import h3 from "../images/home3.jpg";
import h4 from "../images/home4.jpg";
import h5 from "../images/home5.jpg";
import AdvancedSearch from "../components/AdvancedSearch";
import { useNavigate } from "react-router-dom";

const SearchButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="group relative rounded-full bg-slate-900 px-8 py-4 text-white transition-all hover:scale-105 active:scale-95"
  >
    <div className="flex items-center space-x-2">
      <span className="text-lg font-semibold">Start Searching</span>
      <svg
        className="h-4 w-4 transition-transform group-hover:translate-x-1"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.75 8.75L14.25 12L10.75 15.25"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    </div>
    <div className="absolute inset-x-4 -bottom-px h-px bg-gradient-to-r from-blue-500/0 via-blue-500/70 to-blue-500/0 opacity-0 transition-opacity group-hover:opacity-100" />
  </button>
);

const SignupButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="group relative rounded-full bg-green-600 px-8 py-4 text-white transition-all hover:bg-green-700 hover:scale-105 active:scale-95"
  >
    <div className="flex items-center space-x-2">
      <span className="text-lg font-semibold">Sign Up</span>
      <svg
        className="h-4 w-4 transition-transform group-hover:translate-x-1"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.75 8.75L14.25 12L10.75 15.25"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    </div>
    <div className="absolute inset-x-4 -bottom-px h-px bg-gradient-to-r from-blue-500/0 via-blue-500/70 to-blue-500/0 opacity-0 transition-opacity group-hover:opacity-100" />
  </button>
);

// Simplified cards array to only include images
const cards = [
  {
    id: 1,
    className: "md:col-span-2",
    thumbnail: h1,
  },
  {
    id: 2,
    className: "col-span-1",
    thumbnail: h2,
  },
  {
    id: 3,
    className: "col-span-1",
    thumbnail: h3,
  },
  {
    id: 4,
    className: "md:col-span-2",
    thumbnail: h4,
  },
  {
    id: 5,
    className: "md:col-span-2",
    thumbnail: h5,
  },
];

const HomePage = () => {
  const searchRef = React.useRef(null);
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  const scrollToSearch = () => {
    searchRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    if (window.location.hash === '#search-section') {
      document.getElementById('search-section')?.scrollIntoView({ 
        behavior: 'smooth' 
      });
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeIn" }}
      className="min-h-screen bg-[#FAF9F8] flex flex-col"
    >
      <Header />

      <main className="flex-1 flex flex-col object-center mx-auto container py-28">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center py-10">
            <div className="space-y-8">
              <h1 className="text-6xl font-bold">
                <span className="text-black">
                  We find the perfect skincare products for you.
                </span>
              </h1>
              <p className="text-2xl text-black">Completely ad-free.</p>

              <p className="text-gray-700 text-lg max-w-xl">
                Sorcea uses an advanced search algorithm to get the best
                results for you. By adding details to your search such as skin
                type, climate, or acne, your products will become more
                personalized.
              </p>

              <SearchButton onClick={scrollToSearch} />
              <SignupButton onClick={handleSignUp} />
            </div>
            <div className="relative">
              <div className="relative">
                <div className="h-[500px] w-full overflow-hidden rounded-xl">
                  <LayoutGrid cards={cards} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={searchRef} id="search-section">
          <AdvancedSearch />
        </div>
      </main>

      <Footer />
    </motion.div>
  );
};

export default HomePage;

