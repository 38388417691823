import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from 'uuid';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { UAParser } from 'ua-parser-js';
import { Card, CardBody, CardFooter } from "@nextui-org/react";

import Rating from "@mui/material/Rating";
import owl from "../images/owlPeriwinkle.webp";

import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";

import Loading from "../components/Loading.jsx";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const authToken = process.env.REACT_APP_AUTH_TOKEN;

function HeroSection() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialSearchQuery = searchParams.get("q") || "";
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);

  const handleSearch = () => {
    navigate(`/s?q=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <div className="flex flex-col items-end lg:flex-row lg:items-center mt-1 mb-2 max-w-full">
      {/* <Link
        to="/search"
        className="justify-center text-2xl font-semibold whitespace-nowrap text-black md:text-2xl lg:mr-4"
      >
        ← Back to Search
      </Link> */}
      {{ searchQuery } && (
        <section
          id="home"
          className="home flex flex-col lg:py-1 w-full px-3"
        >
          <div className=" sm:px-3 md:px-3 lg:px-5 xl:px-5">
            <div className="relative">
              <input
                type="text"
                className="w-full sm:py-2 md:py-4 pl-10 pr-4 text-gray-700 bg-white border rounded-xl focus:outline-none focus:ring"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="justify-between w-4 h-4 sm:w-5 sm:h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* <div className="flex flex-col mt-3 lg:mt-0">
        <span className="text-[#69443C] text-2xl md:text-4xl max-w-full">
          <span className="text-[#b9a5a1]">Showing results for:&nbsp;</span>
          {searchQuery}
        </span>
      </div> */}
    </div>
  );
}

function ProductCard({
  imgSrc,
  title,
  brand,
  price,
  product_id,
  rating,
  isFavorite,
  onToggleFavorite,
}) {
  const titleWithDashes = title.replace(/ /g, "-");
  const link = `/p/${titleWithDashes}/${product_id}/`;

  const handleClick = async () => {
    const searchId = localStorage.getItem("searchId");
    const searchQuery = localStorage.getItem("searchQuery");
    const clickedAt = new Date().toISOString();
    const parser = new UAParser();
    const deviceInfo = parser.getDevice();
    const device = `${deviceInfo.vendor || 'Unknown'} ${deviceInfo.model || 'Unknown'} ${deviceInfo.type || 'Unknown'}`;

    try {
      await axios.post(
        "/product-clicks", 
        {
          searchId,
          searchQuery,
          productId: product_id,
          clickedAt: clickedAt,
          device: device 
        },
        {
          headers: {
            'authorizationToken': authToken
          },
        }
      );
    }
    catch (error) {
      console.error("Error recording the product click event:", error);
    }
};

  return (
    <div className="h-full px-5 pb-8 sm:px-0 md:px-0">
      <Link
        to={link}
        className="block h-full xl:px-5 sm:px-3 sm:py-7 pb-11 md:shadow-sm text-xl font-medium leading-8 text-black max-md:mt-9"
        onClick={handleClick}
      >
        <div className="h-full transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl rounded-xl shadow-[rgba(0,_0,_0,_0.2)_0px_60px_40px_-7px]">
          <Card
            shadow="sm"
            className="flex flex-col h-full overflow-hidden sm:p-5 md:p-3 p-0"
          >
            <CardBody className="flex-grow">
              <div className="aspect-w-1 aspect-h-1 w-full max-h-[480px] overflow-hidden">
                <img
                  shadow="md"
                  radius="sm"
                  alt={title}
                  className="w-full h-full object-cover object-center"
                  src={imgSrc}
                />
              </div>
            </CardBody>
            <CardFooter className="flex flex-col justify-between">
              <div className="flex flex-row w-full">
                <div className="flex flex-col ml-5 sm:ml-0 sm:mr-6 md:ml-5 lg:ml-6">
                  <div className="text-2xl font-semibold line-clamp-3 max-w-[80rem]">
                    {title}
                  </div>
                  <div className="flex">
                    <Rating
                      name="read-only"
                      value={rating || 0}
                      precision={0.1}
                      readOnly
                      size="medium"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center w-full">
                  <p className="mt-6 text-2xl truncate">{brand}</p>
                  <div className="text-xl font-bold text-lightBlue-700 mr-8 md:text-2xl sm:text-2xl">
                    {price}
                  </div>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </Link>
    </div>
  );
}

function ProductSection({ products, toggleFavorite }) {
  return (
    <section className="mx-auto sm:px-0 md:px-6">
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 md:mt-3 lg:mt-8 xl:mt-12 max-md:max-w-full">
        {products.map((product) => (
          <ProductCard
            key={product._source.id}
            imgSrc={product._source.image}
            title={product._source.product_name || product._source.name}
            brand={product._source.brand}
            price={product._source.sale_price ? `$${Number(product._source.sale_price).toFixed(2)}` : "Price not available"}
            product_id={product._source.id}
            rating={product._source.rating}
            isFavorite={product._source.isFavorite}
            onToggleFavorite={() =>
              toggleFavorite(product.id)
            }
          />
        ))}
      </div>
    </section>
  );
}

export default function ProductPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("q");
    setSearchQuery(query);

    const fetchProducts = async (query) => {
      const searchId = uuidv4(); 

      try {
        const response = await axios.get("/search", {
          params: { q: query },
          headers: {
            'authorizationToken': authToken
          },
        });

        const responseBody = response.data; 
        const searchResults = responseBody.hits.hits || []; 
        console.log(searchResults);
        setProducts(searchResults);  
        localStorage.setItem('searchId', searchId);  
        localStorage.setItem('searchQuery', query);  

      } catch (error) {
        console.error("There was an error fetching the products!", error);
        setProducts([]); 
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      fetchProducts(query);  
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [location.search]);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen"> 
        <Header />
        <div className="flex h-screen w-full items-center justify-center">
          <div className="flex flex-col items-center space-y-4">
            <div className="animate-bounce">
              <img src={owl} alt="Loading" className="h-12 w-12" />
            </div>
            <p className="text-gray-500 dark:text-gray-400">Loading...</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeIn" }}
      className="flex flex-col min-h-screen"
    >
      <Header />
      <main className="flex-grow flex flex-col bg-[#f7f4ee]"> {/* Added flex flex-col */}
        <HeroSection 
          searchQuery={searchQuery} 
          setProducts={setProducts} 
          setLoading={setLoading}
        />
        {loading ? (
          <Loading />
        ) : products.length > 0 ? (
          <ProductSection products={products.slice(0, 6)} />
        ) : (
          <div className="flex-grow flex items-center justify-center">
            <p className="text-gray-500 text-xl">No products found.</p>
          </div>
        )}
      </main>
      <Footer />
    </motion.div>
  );
}
