const suggestions = {
    title: "Hi",
    animated: {
        first: ' "Face wash for sensitive and oily skin, acne-prone under $25" ',
        second: ' "Moisturizers for dry skin with Glycerin and Hyaluronic acid"',
        third: ' "Hypoallergenic tinted sunscreen under $20" ',
        fourth: ' "Reef-friendly facial cleanser" '
    },
    description: "What can we help you find today?",
};

export {
    suggestions
};
