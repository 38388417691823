import React, { useState, useEffect } from 'react';
import owl from "../images/owlPeriwinkle.webp";

function TrustyDiv({ inputValue }) {
  const [messageDefault, setMessageDefault] = useState('');
  const [message1, setMessage1] = useState('');
  const [message2, setMessage2] = useState('');
  const [message3, setMessage3] = useState('');
  const [message4, setMessage4] = useState('');

  const [messageDefaultShown, setMessageDefaultShown] = useState(false);
  const [message1Shown, setMessage1Shown] = useState(false);
  const [message2Shown, setMessage2Shown] = useState(false);
  const [message3Shown, setMessage3Shown] = useState(false);
  const [message4Shown, setMessage4Shown] = useState(false);

  const messagesDefault = [
    'Welcome! Let\'s get started.',
    'Start typing to begin your search!',
    'Let\'s find what you need. Start typing!',
    'Find your perfect match. Search away!'
  ]

  const messages1 = [
    'Tell me more to help me find you the best products!',
    'Give me more insight to the perfect products for you!',
    'The more you tell me, the better I can help!',
    'Help me understand your needs by telling me more!'
  ];

  const messages2 = [
    'Try adding more specifics about your skin type or price point.',
    'Tell us a bit more about your skin concerns and preferences.',
    'Let\'s narrow down your options. Tell us more about your skin type and budget.',
    'The more specific, the better the match.'
  ]

  const messages3 = [
    'Almost there, just a bit more!',
    'Just a bit more to the most personalized results!',
    'Almost there! A little more detail will get you closer to your ideal product.',
    'Almost there! Just a few more clicks to your perfect match.'
  ]

  const messages4 = [
    'You\'re all set. Hit enter and let\'s dive in!',
    'There you go! Hit enter!',
    'Ready to search! Hit enter!',
    'You\'re all sorted out! Time to find your match by hitting enter!'
  ]

  useEffect(() => {
    if(inputValue.length == 0 && !messageDefaultShown) {
      const randomIndexDefault = Math.floor(Math.random() * 4);
      const selectedMessageDefault = messagesDefault[randomIndexDefault];

      setMessageDefault(selectedMessageDefault);
      setMessageDefaultShown(true);
    } 

    if(inputValue.length > 0 && inputValue.length <= 20 && !message1Shown) {
      const randomIndex1 = Math.floor(Math.random() * 4);
      const selectedMessage1 = messages1[randomIndex1];

      setMessage1(selectedMessage1);
      setMessage1Shown(true);
    }

    if(20 < inputValue.length <= 35 && !message2Shown) {
      const randomIndex2 = Math.floor(Math.random() * 4);
      const selectedMessage2 = messages2[randomIndex2];

      setMessage2(selectedMessage2);
      setMessage2Shown(true);
    }

    if(35 < inputValue.length <= 50 && !message3Shown) {
      const randomIndex3 = Math.floor(Math.random() * 4);
      const selectedMessage3 = messages3[randomIndex3];

      setMessage3(selectedMessage3);
      setMessage3Shown(true);
    }

    if(inputValue.length > 50 && !message4Shown) {
      const randomIndex4 = Math.floor(Math.random() * 4);
      const selectedMessage4 = messages4[randomIndex4];

      setMessage4(selectedMessage4);
      setMessage4Shown(true);
    }
  }, [inputValue]);

  return (
    <div
      className={`w-full absolute flex flex-row gap-4 bottom-0 right-0 items-center text-black p-5 rounded-md sm:w-auto ${inputValue.length >= 0 ? 'opacity-100' : 'opacity-0'}`}
      style={{ transition: 'opacity 0.5s ease-in-out' }}
    >
      {inputValue.length == 0 && inputValue.length <= 20 && <p>{ messageDefault }</p>}
      {inputValue.length > 0 && inputValue.length <= 20 && <p>{ message1 }</p>}
      {inputValue.length > 20 && inputValue.length <= 35 && <p> {message2} </p>}
      {inputValue.length > 35 && inputValue.length <= 50 && <p> {message3} </p>}
      {inputValue.length > 50 && <p> {message4} </p>}
      <img src={owl} alt="Periwinkle Owl" width="50" height="50"></img>
    </div>
  );
}

export default TrustyDiv;