import owl from "../images/owlPeriwinkle.webp"

export default function Component() {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-gray-100 light:bg-gray-900">
      <div className="flex flex-col items-center space-y-4">
        <div className="animate-bounce">
          <img
            src={owl}
            alt="Loading"
            className="h-12 w-12"
          />
        </div>
        <p className="text-gray-500 dark:text-gray-400">Loading...</p>
      </div>
    </div>
  );
}