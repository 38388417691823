"use client";
import React, { useEffect, useState } from "react";
import { cn } from "../../utils/cn";

export const LayoutGrid = ({ cards }) => {
  const containerRef = React.useRef(null);
  const scrollerRef = React.useRef(null);
  const [start, setStart] = useState(false);

  useEffect(() => {
    addAnimation();
  }, []);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });
      setStart(true);
    }
  }

  const getCardSize = (index) => {
    switch (index) {
      case 0:
        return "col-span-2 row-span-2 h-[300px]";
      case 1:
        return "col-span-2 row-span-2 h-[300px]";
      case 2:
        return "col-span-1 row-span-2 h-[190px]";
      case 3:
        return "col-span-2 row-span-2 h-[190px]";
      case 4:
        return "col-span-1 row-span-1 h-[190px]";
      default:
        return "col-span-1 row-span-1 h-[190px]";
    }
  };

  return (
    <div
      ref={containerRef}
      className="relative h-full w-full overflow-hidden rounded-xl"
    >
      <div
        ref={scrollerRef}
        className={cn(
          "flex gap-4 w-max",
          start && "animate-scroll",
          // "hover:[animation-play-state:paused]"
        )}
      >
        <div className="grid grid-cols-4 gap-4 p-4">
          {cards.map((card, index) => (
            <div
              key={card.id}
              className={cn(
                "relative rounded-xl overflow-hidden",
                "transition-transform duration-700 hover:scale-[1.02]",
                getCardSize(index)
              )}
            >
              <img
                src={card.thumbnail}
                alt="Product"
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LayoutGrid;