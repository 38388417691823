// import React from "react";
// import {
//   FaInstagram,
//   FaLinkedin,
//   FaTiktok,
// } from "react-icons/fa";

// const Footer = () => {
//   return (
//     <footer className="mt-auto py-4 bg-[#f7f4ee]">
//       <div className="container mx-auto flex flex-col items-center justify-between px-2">
//         <div className="text-gray-250 flex justify-between items-center mb-4 space-x-20">
//           <a
//             href="https://www.linkedin.com/company/searchowl-us/"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaLinkedin className="text-2xl md:text-xl sm:text-sm" />
//           </a>
//           <a
//             href="https://www.instagram.com/search_owl/"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaInstagram className="text-2xl md:text-xl sm:text-sm space-x-3" />
//           </a>
//           <a
//             href=" https://www.tiktok.com/@search_owl"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaTiktok className="text-2xl md:text-xl sm:text-sm" />
//           </a>
//         </div>
//         <div className="text-gray-400 text-sm md:text-xs text-center">
//           <div className="flex space-x-3">
//             <a
//               href="../legal/disclaimer"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <p className="text-xs">Disclaimer</p>
//             </a>
//             <a
//               href="../legal/privacy"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <p>Privacy Policy</p>
//             </a>
//             <a
//               href="../legal/terms"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <p>Terms and Conditions</p>
//             </a>
//             <a
//               href="../sitemap"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <p>Sitemap</p>
//             </a>
//           </div>

//           <span className="mb-4 text-center text-sm md:text-xs text-gray-350 sm:!mb-0">
//             SearchOwl © {new Date().getFullYear()}
//           </span>
//         </div>
//       </div>
//     </footer>
//   );
// };
// export default Footer;

import React, { useState } from 'react';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { IoIosAdd } from 'react-icons/io';

const Footer = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };

  // Reusable social media icon component
  const SocialIcon = ({ href, icon: Icon }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-white hover:text-gray-800 transition-colors"
    >
      <Icon className="w-6 h-6" />
    </a>
  );

  return (
    <footer className="bg-[#a2a3bb] text-white mt-auto">
      <div className="hidden lg:block mx-auto w-11/12">
        <div className="flex justify-between items-start py-8">
          <div className="flex-shrink-0">
            <div className="flex flex-col items-start gap-0" style={{ letterSpacing: '0.15em' }}>
              <h2 className="text-5xl font-extrabold mb-0">SEARCHOWL</h2>
              <span className="text-4xl font-black tracking-wider w-full text-left" 
                    style={{ 
                      letterSpacing: '0.5em', 
                      marginLeft: '2px',
                      transform: 'scale(0.95, 1)',
                      transformOrigin: 'left'
                    }}>
                SKINCARE
              </span>
              <span className="text-xl font-semibold w-full text-left" 
                    style={{ 
                      letterSpacing: '0.25em',
                      marginLeft: '2px',
                      transform: 'scale(0.92, 1)',
                      transformOrigin: 'left'
                    }}>
                shopping built for you
              </span>
            </div>
          </div>
          
          <div className="flex space-x-16">
            <div>
              <h3 className="text-xl font-semibold mb-4">INQUIRE</h3>
              <ul className="space-y-2">
                <li><a href="/about" className="hover:text-gray-800">ABOUT</a></li>
                <li><a href="/contact" className="hover:text-gray-800">CONTACT</a></li>
                <li><a href="/signup" className="hover:text-gray-800">SIGN UP</a></li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-4">TERMS</h3>
              <ul className="space-y-2">
                <li><a href="/disclaimer" className="hover:text-gray-800">DISCLAIMER</a></li>
                <li><a href="/sitemap" className="hover:text-gray-800">SITEMAP</a></li>
                <li><a href="/privacy-policy" className="hover:text-gray-800">PRIVACY POLICY</a></li>
                <li><a href="/terms-and-conditions" className="hover:text-gray-800">TERMS AND CONDITIONS</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="py-4 flex justify-between items-center border-t border-gray-300">
          <p className="text-sm">SearchOwl © {new Date().getFullYear()}</p>
          <div className="flex space-x-6">
            <SocialIcon href="https://www.instagram.com/search_owl/" icon={FaInstagram} />
            <SocialIcon href="https://www.linkedin.com/company/searchowl-us/" icon={FaLinkedin} />
            <SocialIcon href="https://www.tiktok.com/@search_owl" icon={FaTiktok} />
          </div>
        </div>
      </div>

      <div className="lg:hidden w-11/12 mx-auto py-8">
        {/* <h2 className="text-5xl font-bold mb-6">SearchOwl</h2> */}
        <div className="flex-shrink-0">
            <div className="flex flex-col items-start gap-0" style={{ letterSpacing: '0.15em' }}>
              <h2 className="text-5xl font-extrabold mb-0">SEARCHOWL</h2>
              <span className="text-3xl font-black tracking-wider w-full text-left" 
                    style={{ 
                      letterSpacing: '0.7em', 
                      marginLeft: '2px',
                      transform: 'scale(0.95, 1)',
                      transformOrigin: 'left'
                    }}>
                SKINCARE
              </span>
              <span className="text-xl font-semibold w-full text-left" 
                    style={{ 
                      letterSpacing: '0.25em',
                      marginLeft: '2px',
                      transform: 'scale(0.92, 1)',
                      transformOrigin: 'left'
                    }}>
                shopping built for you
              </span>
            </div>
          </div>
        <div className="flex space-x-4 my-6">
          <SocialIcon href="https://www.instagram.com/search_owl/" icon={FaInstagram} />
          <SocialIcon href="https://www.linkedin.com/company/searchowl-us/" icon={FaLinkedin} />
          <SocialIcon href="https://www.tiktok.com/@search_owl" icon={FaTiktok} />
        </div>

        {['INQUIRE', 'TERMS'].map((section) => (
          <div key={section} className="border-t border-gray-300">
            <button
              className="w-full py-4 flex justify-between items-center"
              onClick={() => toggleSection(section)}
            >
              <span className="font-semibold">{section}</span>
              <IoIosAdd
                className={`w-6 h-6 transform transition-transform ${
                  expandedSection === section ? 'rotate-45' : ''
                }`}
              />
            </button>
            
            {expandedSection === section && (
              <div className="pb-4">
                {section === 'INQUIRE' && (
                  <ul className="space-y-2">
                    <li><a href="/partner">PARTNER</a></li>
                    <li><a href="/contact">CONTACT</a></li>
                    <li><a href="/signup">SIGN UP</a></li>
                    <li><a href="/about">ABOUT</a></li>
                  </ul>
                )}
                {/* {section === 'US' && (
                  <ul className="space-y-2">
                    <li><a href="/about">ABOUT</a></li>
                    <li><a href="/parent-company">PARENT COMPANY</a></li>
                  </ul>
                )} */}
                {section === 'TERMS' && (
                  <ul className="space-y-2">
                    <li><a href="/disclaimer">DISCLAIMER</a></li>
                    <li><a href="/sitemap">SITEMAP</a></li>
                    <li><a href="/privacy-policy">PRIVACY POLICY</a></li>
                    <li><a href="/terms-and-conditions">TERMS AND CONDITIONS</a></li>
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}

        <p className="text-sm mt-6 border-t border-gray-300 pt-4">
          SearchOwl © {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;