// Quiz.jsx
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import '../pages/quiz/Quiz.css';
import emailLogo from '../pages/quiz/logos/envelope-line-icon.png';
import facebookLogo from '../pages/quiz/logos/facebook-app-round-icon.png';
import twitterLogo from '../pages/quiz/logos/twitter.png';
import whatsappLogo from '../pages/quiz/logos/whatsapp-icon.png';
import smsLogo from '../pages/quiz/logos/speaking-bubbles-black-icon.png';
import quizWelcome from '../images/quiz-welcome.jpg';
import { Flex } from "@aws-amplify/ui-react";

// Questions
const questions = [
  {
    question: "How does your skin feel after cleansing?",
    answers: [
      "Tight and dry",
      "Comfortable and normal",
      "Oily in some areas, dry in others",
      "Shiny and oily",
      "Slightly irritated or red",
      "Feels rough and bumpy",
    ],
  },
  {
    question: "How often do you experience breakouts?",
    answers: [
      "Rarely",
      "Occasionally",
      "Frequently in the T-zone (forehead, nose, chin)",
      "Frequently all over",
      "Almost never, but experience redness instead",
      "Regularly, especially after using new products",
    ],
  },
  {
    question: "How does your skin react to moisturizers?",
    answers: [
      "Absorbs quickly and feels hydrated",
      "Feels balanced and comfortable",
      "Feels oily in some areas, normal in others",
      "Feels greasy and shiny",
      "Feels slightly irritated or sensitive",
      "Feels sticky or uncomfortable",
    ],
  },
  {
    question: "How does your skin look by midday?",
    answers: [
      "Dry and flaky",
      "Normal",
      "Oily in the T-zone",
      "Oily all over",
      "Red or flushed",
      "Uneven with dry patches",
    ],
  },
  {
    question: "How visible are your pores?",
    answers: [
      "Small and barely visible",
      "Medium-sized",
      "Large in the T-zone",
      "Large and visible all over",
      "Barely visible but prone to redness",
      "Small but clogged",
    ],
  },
  {
    question: "How does your skin react to new skincare products?",
    answers: [
      "Easily irritated and sensitive",
      "No significant reaction",
      "Sometimes oily, sometimes dry",
      "Often causes breakouts",
      "Frequently experiences redness",
      "Skin feels tight or uncomfortable",
    ],
  },
  {
    question: "How would you describe the texture of your skin?",
    answers: [
      "Rough and flaky",
      "Smooth and even",
      "Smooth in some areas, rough in others",
      "Greasy and shiny",
      "Smooth but prone to redness",
      "Bumpy or uneven",
    ],
  },
];

// Title and descriptions for results
const results = {
  A: {
    title: "Dry Skin",
    description:
      "Your skin tends to feel tight, dry, and may appear flaky. It is often prone to irritation and sensitivity. Using hydrating and gentle skincare products can help maintain moisture.",
  },
  B: {
    title: "Normal Skin",
    description:
      "Your skin feels comfortable and balanced. It is not too oily or too dry and rarely has breakouts. Maintaining a regular skincare routine will help keep your skin healthy.",
  },
  C: {
    title: "Combination Skin",
    description:
      "You have a mix of oily and dry areas, typically with an oily T-zone (forehead, nose, and chin) and drier cheeks. Using a balanced skincare routine that targets both oil control and hydration will work best.",
  },
  D: {
    title: "Oily Skin",
    description:
      "Your skin is shiny and greasy all over, with larger pores and a tendency to break out. Using oil-free and non-comedogenic products can help manage excess oil and prevent breakouts.",
  },
  E: {
    title: "Sensitive Skin",
    description:
      "Your skin is easily irritated, prone to redness, and may react strongly to various products. Opt for gentle, fragrance-free, and hypoallergenic skincare products to soothe and protect your skin.",
  },
  F: {
    title: "Combination of Dry and Sensitive Skin",
    description:
      "Your skin experiences both dryness and sensitivity, with uneven texture or rough patches. Focus on nourishing and barrier-repairing products that calm irritation while providing deep hydration.",
  },
};

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [quizFinished, setQuizFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);

  const handleAnswerSelect = (answerIndex) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestion] = answerIndex;
    setSelectedAnswers(updatedAnswers);
  };

  // Makes question a required answer
  const handleNext = () => {
    if (selectedAnswers[currentQuestion] === undefined) {
      return; // Prevent advancing without selecting an answer
    }

    if (currentQuestion === questions.length - 1) {
      setLoading(true);
      setTimeout(() => {
        setQuizFinished(true);
        setLoading(false);
      }, 7000);
    } else {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  // Allows user to track back
  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  // Calculates the results depending on which answer is the majority
  // If two answers are the majority, then the code spews out the first answer alphabetically
  const getResult = () => {
    const answerFrequency = selectedAnswers.reduce((acc, answer) => {
      const letter = String.fromCharCode(65 + answer);
      acc[letter] = (acc[letter] || 0) + 1;
      return acc;
    }, {});

    const mostFrequentAnswer = Object.keys(answerFrequency).sort(
      (a, b) => answerFrequency[b] - answerFrequency[a]
    )[0];
    return results[mostFrequentAnswer] || results["A"];
  };

  // Retrives quiz results
  const result = getResult();
  const quizUrl = "https://searchowl.us/quiz";

  // Background colors
  const getBackgroundColor = (questionIndex, answerIndex) => {
    const colorSchema = [
      ['#EC2AA5', '#EC48B0', '#EA64BA', '#EA85C5', '#EA9DCE', '#F1C1E0'], // Question 1
      ['#EB8722', '#EB943D', '#ECA45B', '#EDB379', '#EEC69F', '#F5D9BD'], // Question 2
      ['#EEC326', '#ECC842', '#ECCD5A', '#ECD378', '#EBD994', '#F2E6BA'], // Question 3
      ['#5ECF0F', '#6CCE28', '#7CCF42', '#90CF65', '#A5D386', '#C1E1AB'], // Question 4
      ['#1CDECA', '#3ADFCD', '#5BDFD1', '#7AE1D6', '#8DDFD7', '#ACE6E0'], // Question 5
      ['#3B90E4', '#519BE4', '#71A9E1', '#96BEE5', '#AACBEC', '#C7DEF5'], // Question 6
      ['#BB8DFD', '#C198FD', '#C09EF0', '#CFB5F3', '#DECDF7', '#E7DAF9']  // Question 7
    ];
    return colorSchema[questionIndex][answerIndex];
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Header />

      {/* WELCOME PAGE */}
      {!quizStarted ? (
        <div className="welcome-container">
          <h2 className="subtitle">Elevate Your Skincare Routine</h2>
          <p>Learn about your skin with our skin type quiz!</p>
          <img src={quizWelcome} alt="Skincare" className="skincare-image" />
          <button className="start-button" onClick={() => setQuizStarted(true)}>Start &gt;&gt;</button>
        </div>
      ) : (
        // START OF QUIZ
        <div className={`quiz-container ${loading ? "quiz-loading" : ""}`}>
          {!quizFinished && (
            <>
              <div className="quiz-header">
                <p className="progress">{`${currentQuestion + 1}/${
                  questions.length
                }`}</p>
              </div>
              <div className="quiz-content">
                <h4>
                  {questions[currentQuestion]?.question ||
                    "Loading question..."}
                </h4>
                <div className="answers-container">
                  {questions[currentQuestion]?.answers.map((answer, index) => (
                    // SLIDE IN ANIMATION FOR FIRST QUESTION
                    <motion.button
                      key={index}
                      className={`answer answer-${index + 1} ${
                        selectedAnswers[currentQuestion] === index
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleAnswerSelect(index)}
                      initial={{ x: -100, opacity: 0 }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        scale:
                          selectedAnswers[currentQuestion] === index
                            ? 1.025
                            : 1,
                      }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      style={{
                        backgroundColor: getBackgroundColor(
                          currentQuestion,
                          index
                        ),
                      }}
                    >
                      {answer}
                    </motion.button>
                  ))}
                </div>
              </div>
              {/* Allows user to proceed or not proceed */}
              <div className="quiz-navigation">
                {currentQuestion > 0 && (
                  <button onClick={handleBack}>Back</button>
                )}
                <button
                  onClick={handleNext}
                  disabled={selectedAnswers[currentQuestion] === undefined}
                >
                  {currentQuestion === questions.length - 1 ? "Finish" : "Next"}
                </button>
              </div>
            </>
          )}

          {/* Loading bar for users */}
          {loading && (
            // LOADING BAR LOGIC:
            // First 90% of bar is completed in 1.5 seconds
            // Last 10% of bar is completed in 5.5 seconds
            // TOTAL: 7 seconds
            <motion.div className="loading-overlay">
              <motion.div
                className="popup"
                style={{ width: '30rem', height:'10rem' }}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <p style={{ fontSize: '3rem' }}>Analyzing results...</p>
                <motion.div
                  className="loading-bar"
                  initial={{ width: 0 }}
                  animate={{ width: ["0%", "90%", "100%"] }}
                  transition={{ duration: 7, times: [0, 0.21, 1] }}
                />
              </motion.div>
            </motion.div>
          )}

          {/* Quiz results */}
          {quizFinished && (
            <div className="results-container">
              <h2 className="results-title"><span className="text-[#8A4AF3]">Your Results:</span> {result.title}</h2>
              <div className="result-summary">
                <p className="results-description">{result.description}</p>
              </div>
                {/* Sharing Section */}
                <div className="interactives">
                  <div className="share-buttons">
                    {/* Custom Made SMS Sharing */}
                    <a
                      href={`sms:?&body=I used the SearchOwl skincare quiz to find my skin type. Try the quiz here: ${quizUrl}`}
                      className="sms-share-button"
                    >
                      <img
                        src={smsLogo}
                        alt="Share via SMS"
                        className="share-icon"
                      />
                    </a>

                    {/* ALL SHARE BUTTONS FROM REACT-SHARE */}
                    <FacebookShareButton
                      url={quizUrl}
                      quote="I used the SearchOwl skincare quiz to find my skin type. Try the quiz here: https://searchowl.us/quiz"
                    >
                      <img
                        src={facebookLogo}
                        alt="Share on Facebook"
                        className="share-icon"
                      />
                    </FacebookShareButton>
                    {/* <TwitterShareButton url={quizUrl} title="I used the SearchOwl skincare quiz to find my skin type. Try the quiz here: ">
                      <img src={twitterLogo} alt="Share on Twitter" className="share-icon" />
                    </TwitterShareButton> */}
                    <WhatsappShareButton url={quizUrl} title="I used the SearchOwl skincare quiz to find my skin type. Try the quiz here: https://searchowl.us/quiz">
                      <img src={whatsappLogo} alt="Share on WhatsApp" className="share-icon" />
                    </WhatsappShareButton>
                    <EmailShareButton
                      url={quizUrl}
                      subject="Check out my quiz results"
                      body="I used the SearchOwl skincare quiz to find my skin type. Try the quiz here: https://searchowl.us/quiz"
                    >
                      <img
                        src={emailLogo}
                        alt="Share via Email"
                        className="share-icon"
                      />
                    </EmailShareButton>
                  </div>
                  
                  <div className="redirect-buttons">
                    {/* REDIRECTS USER TO /ACCOUNT */}
                    <button
                      className="redirect-button-account"
                      onClick={() => window.location.href = "/profile"}
                    >
                      Create Account
                    </button>
                    <h3>Or</h3>
                    {/* REDIRECTS USER TO /SEARCH */}
                    <button
                      className="redirect-button"
                      onClick={() => window.location.href = "/home#search-section"}
                    >
                      Continue as Guest
                  </button>
                  </div>
                </div>
              </div>
          )}
        </div>
      )}
      <Footer />
    </motion.div>
  );
};

export default Quiz;
